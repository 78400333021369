"use client";
import { useLocation } from "@remix-run/react";
import { useEffect, useState } from "react";

interface GoogleAnalyticsProps {
  GA_MEASUREMENT_ID: string;
  consentCookie: boolean | undefined;
}

export default function GoogleAnalytics({
  GA_MEASUREMENT_ID,
  consentCookie = undefined,
}: GoogleAnalyticsProps) {
  const location = useLocation();
  const [isInitialized, setIsInitialized] = useState(false);
  const [initialConsent, setInitialConsent] = useState(consentCookie);

  useEffect(() => {
    if (!GA_MEASUREMENT_ID) return;

    const prepareAnalytics = () => {
      window.dataLayer = window.dataLayer || [];
      window.gtag = function gtag() {
        window.dataLayer.push(arguments);
      };

      window.gtag("js", new Date());

      // Set initial consent state
      const consentValue = consentCookie ? "granted" : "denied";
      window.gtag("consent", "default", {
        ad_storage: consentValue,
        ad_personalization: consentValue,
        ad_user_data: consentValue,
        analytics_storage: consentValue,
        wait_for_update: 500,
      });

      // Initialize GA with the measurement ID
      window.gtag("config", GA_MEASUREMENT_ID, {
        page_path: location.pathname,
      });
    };

    // Load the Google Analytics script only once
    const loadGoogleAnalytics = () => {
      if (document.querySelector(`script[src*="googletagmanager.com/gtag"]`)) {
        return;
      }

      const script = document.createElement("script");
      script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`;
      script.async = true;
      document.head.appendChild(script);
    };

    prepareAnalytics();
    loadGoogleAnalytics();

    setIsInitialized(true);

    return () => {
      const script = document.querySelector(
        `script[src*="googletagmanager.com/gtag"]`,
      );
      if (script) {
        document.head.removeChild(script);
      }
    };
  }, [GA_MEASUREMENT_ID]);

  // Handle consent changes
  useEffect(() => {
    if (!isInitialized || consentCookie === initialConsent) return;

    const consentValue = consentCookie ? "granted" : "denied";
    window.gtag("consent", "update", {
      ad_storage: consentValue,
      ad_personalization: consentValue,
      ad_user_data: consentValue,
      analytics_storage: consentValue,
    });
  }, [consentCookie, isInitialized, initialConsent]);

  // Handle client-side navigation
  useEffect(() => {
    if (!isInitialized) return;

    window.gtag("config", GA_MEASUREMENT_ID, {
      page_path: location.pathname,
      page_location: window.location.href,
    });
  }, [location, GA_MEASUREMENT_ID, isInitialized]);

  return null;
}
