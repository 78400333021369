import {
  ActionFunctionArgs,
  data,
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
} from "@remix-run/node";
import {
  isRouteErrorResponse,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useFetcher,
  useLoaderData,
  useRouteError,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import CookieConsent from "~/components/CookieConsent";
import GoogleAnalytics from "~/components/GoogleAnalytics";
import {
  getConsentCookie,
  updateConsentCookie,
} from "~/lib/services/cookies.server";
import { getEnvs } from "~/lib/utils/env.server";
import stylesheet from "./tailwind.css?url";

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: stylesheet },
];

export const meta: MetaFunction = () => {
  return [{ title: "AIFred - Dein Freelancer Assistent" }];
};

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const consentCookie = await getConsentCookie(request);

  return data({
    GA_MEASUREMENT_ID: getEnvs().GA_MEASUREMENT_ID,
    consentCookie,
  });
};

export async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData();
  const consent = formData.get("consent");

  let cookieHeader = "";

  if (consent != null) {
    cookieHeader = await updateConsentCookie(consent == "true");
  }

  return data(
    {},
    {
      headers: {
        "Set-Cookie": cookieHeader,
      },
    },
  );
}

export function Layout({ children }: { children: React.ReactNode }) {
  const { consentCookie, GA_MEASUREMENT_ID } = useLoaderData<typeof loader>();
  const fetcher = useFetcher<typeof action>();

  const onCookieClick = (consent: boolean) => {
    fetcher.submit({ consent }, { method: "POST" });
  };

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <Meta />
        <Links />
      </head>
      <body className="h-dvh overflow-hidden">
        {children}
        <ScrollRestoration />
        <Scripts />

        <CookieConsent
          variant="default"
          cookieValue={consentCookie}
          onAcceptCallback={() => onCookieClick(true)}
          onDeclineCallback={() => onCookieClick(false)}
        />
        <GoogleAnalytics
          consentCookie={consentCookie}
          GA_MEASUREMENT_ID={GA_MEASUREMENT_ID}
        />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  // return <div>Something went wrong</div>;

  if (isRouteErrorResponse(error)) {
    return (
      <div>
        <h1>
          {error.status} {error.statusText}
        </h1>
        <p>{error.data}</p>
      </div>
    );
  } else if (error instanceof Error) {
    return (
      <div>
        <h1>Error</h1>
        <p>{error.message}</p>
        <p>The stack trace is:</p>
        <pre>{error.stack}</pre>
      </div>
    );
  } else {
    return <h1>Unknown Error</h1>;
  }
};

export default function App() {
  return <Outlet />;
}
